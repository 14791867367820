import React, { Suspense, lazy } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useMemo, useCallback, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { unflatten, flatten } from "flat";
//checks for prop changes to avoid unnecessary rerendering. Does not solve runtime error, only functions as checker.

import {
  createBrowserRouter,
  RouterProvider,
  redirect,
  Navigate,
  Outlet,
  defer,
  useLocation,
  useActionData,
  useParams,
  json,
  useRouteLoaderData,
} from "react-router-dom";

import { menuListLoader } from "./components/store/crudActions";
import Assignment from "./components/dashboard/pages/Assignment";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { useColorScheme } from "@mui/material/styles";

//theme file to use with themeprovider component - themeprovider will wrap entire react fragment with consistent theme
// import theme from "./styling/theme";

import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { Box } from "@mui/material";
import { themeSettings } from "./styling/theme";
// import GlobalStyles from "./styling/GlobalStyles";

import myAxios from "./components/MyAxios";
const Main = lazy(() => import("./components/Main"));
import { NavBar } from "./components/NavBar";

import { deleteAssetType } from "./components/store/crudActions";

//loaders
// import { NavBarLoader } from "./components/NavBar";
// import RecTabs from "./home/RecTabs";
// const About = lazy(()=> import("./components/About"));
const HomeTabs = lazy(() => import("./components/home/HomeTabs"));
import HomeTabsRouter from "./components/home/HomeTabsRouter";
// const Terms = lazy(()=> import("./components/TermPage"));
// const Privacy = lazy(()=> import("./components/Privacy"));
// const LoginPage = lazy(()=> import("./components/register_login/LoginPage"));
import { LoginPage } from "./components/register_login/LoginPage";
import pMinDelay from "p-min-delay";

import { writeToCache } from "./cache";
import { readFromCache } from "./cache";
// const Cart = lazy(()=> import("./components/dashboard/Cart"));
// const Payments = lazy(()=> import("./components/dashboard/Payments"));
// const PassRecovery = lazy(()=> import("./components/register_login/PassRecovery"));
const ErrorPage = lazy(() => import("./ErrorPage"));
const ReDirectPage = lazy(() => import("./ReDirectPage"));

const Layout = lazy(() => import("./Layout"));

import { OverviewLoader } from "./components/home/Overview";
import { HIWLoader } from "./components/home/Howitworks";
import { checkUser } from "./components/store/zuCustActions";

// import Overview from "./components/home/OverviewLazy";
// import SignUp from "./components/home/SignUpLazy";
// import HowItWorks from "./components/home/HowitworksLazy";

const Overview = lazy(() => import("./components/home/OverviewLazy"));
const SignUp = lazy(() => import("./components/home/SignUpLazy"));
const HowItWorks = lazy(() => import("./components/home/HowitworksLazy"));

// const Overview = lazy(() =>
//   pMinDelay(import("./components/home/OverviewLazy"), 100)
// );
// const HowItWorks = lazy(() =>
//   pMinDelay(import("./components/home/HowitworksLazy"), 100)
// );
// const SignUp = lazy(() =>
//   pMinDelay(import("./components/home/SignUpLazy"), 100)
// );
const SummaryDash = lazy(() => import("./components/dashboard/SummaryDash"));

import { useTokenStore } from "./components/store/zuTokenStore";
import { useCustStore } from "./components/store/zuCustStore";
import { useCartStore } from "./components/store/zuCartStore";
import { refreshCart } from "./components/store/zuCartActions";

import { PassRecovery } from "./components/register_login/PassRecovery";
// import MuiDBLayoutClipped from "./components/dashboard/MuiDBLayoutClipped";
// import MuiDashboardLayout from "./components/dashboard/MuiDashboardLayout";
import { ConfirmProvider } from "material-ui-confirm";

const DashboardLayout = React.lazy(() =>
  import("./components/dashboard/DashboardLayout")
);
const MissionControl = React.lazy(() =>
  import("./components/dashboard/pages/MissionControl")
);
const Services = React.lazy(() =>
  import("./components/dashboard/pages/services/services-mrt")
);
// import DashBdDynForm from "./components/dashboard/pages/DashBdDynForm";
const DashBdDynForm = React.lazy(() =>
  import("./components/dashboard/pages/DashBdDynForm")
); // import Team from "./components/dashboard/pages/Team/team";
const MrtList = React.lazy(() =>
  import("./components/dashboard/pages/MrtListFiltered")
);
const Assign = React.lazy(() =>
  import("./components/dashboard/pages/MrtList2")
);
const DashBdOneStepForm = React.lazy(() =>
  import("./components/dashboard/pages/DashBdOneStepForm_obs")
);
const DashBdMultiStepForm = React.lazy(() =>
  import("./components/dashboard/pages/DashBdMultiStepForm")
);
import Receipt from "./components/home/Receipt";
// import Receipts from "./components/dashboard/pages/receipts/transactions";

import { updUser } from "./components/store/zuCustActions";
import * as jwtencoder from "jwt-encode";
import { refreshCust } from "./components/store/zuCustActions";
import { callUICrud } from "./components/store/crudActions";
import DashBdCartEdit from "./components/dashboard/pages/DashBdCartEdit";

function App(props) {
  //local state definitions with corresponding setter metods.
  // const { classes } = props;
  // const [selectedTab, setSelectedTab] = useState(null);
  // const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  // const [blogPosts, setBlogPosts] = useState([]);
  // const [dialogOpen, setDialogOpen] = useState(null);
  // const [isCookieRulesDialogOpen, setIsCookieRulesDialogOpen] = useState(false);
  // const x = () => {};

  const tmlValuesLoader = async (ff, fVals, params) => {
    var tmlValues = {};
    var tmlff;
    var tmlfvals;

    var tmlffs = ff.filter(
      (ffrow) =>
        ffrow.ui_data.type === "tblmenulist" || ffrow.ui_data.type === "mrt"
    );

    console.log("tml", tmlffs);

    for (var i = 0; i < tmlffs.length; ++i) {
      console.log("tmlffs[i]", tmlffs[i]);
      if (tmlffs[i].ui_data.type === "mrt") {
        tmlfvals = await callUICrud(tmlffs[i]?.ui_data?.tml_entity_name, "R", {
          user_id: useCustStore.getState().currentUser.id,
          grp_id: useCustStore.getState().currentUser.grp_id,
          cust_id: useCustStore.getState().currentUser.cust_id,
          sa_cust_id:
            useCustStore.getState().currentUser.role_name === "System Admin"
              ? useCustStore.getState().currentUser.cust_id
              : null,
        });

        console.log("tmlfvals mrt case", tmlfvals);

        tmlff = await callUICrud("ff", "R", {
          user_id: useCustStore.getState().currentUser.id,
          grp_id: useCustStore.getState().currentUser.grp_id,
          cust_id: useCustStore.getState().currentUser.cust_id,

          parent_ent: tmlffs[i]?.ui_data?.tml_entity_name,
        });

        console.log("tmlff mrt case", tmlff);

        tmlValues = {
          ...tmlValues,
          [tmlffs[i].ui_data.colname]: {
            ff: tmlff.filter((ff) => ff.ui_data.showin_list === "Y"),
            fvals: tmlfvals,
            actions: tmlffs[i].ui_data.tml_action_entity
              ? useCustStore
                  .getState()
                  .valactions.filter(
                    (action) =>
                      action.entity_name === tmlffs[i].ui_data.tml_action_entity
                  )
              : useCustStore
                  .getState()
                  .valactions.filter(
                    (action) =>
                      action.entity_name === tmlffs[i].ui_data.tml_entity_name
                  ),
          },
        };
      } else {
        tmlfvals = await callUICrud(tmlffs[i]?.ui_data?.tml_entity_name, "R", {
          user_id: useCustStore.getState().currentUser.id,
          grp_id: useCustStore.getState().currentUser.grp_id,
          cust_id: useCustStore.getState().currentUser.cust_id,
          sa_cust_id:
            useCustStore.getState().currentUser.role_name === "System Admin"
              ? useCustStore.getState().currentUser.cust_id
              : null,
          // [tmlffs[i]?.ui_data?.tml_filter_keys]: fVals.filter(
          //   (val) => val.id == params.id
          // )[0][tmlffs[i]?.ui_data?.tml_filter_keys],
        });

        console.log("tmlfvals tml case", tmlfvals);

        tmlff = await callUICrud("ff", "R", {
          user_id: useCustStore.getState().currentUser.id,
          grp_id: useCustStore.getState().currentUser.grp_id,
          cust_id: useCustStore.getState().currentUser.cust_id,

          parent_ent: tmlffs[i].ui_data.tml_entity_name,
        });

        console.log("tmlff tml case", tmlff);

        tmlValues = {
          ...tmlValues,
          [tmlffs[i].ui_data.colname]: {
            ff: tmlff.filter((ff) => ff.ui_data.showin_tml === "Y"),
            fvals: tmlfvals,
            actions: tmlffs[i].ui_data.tml_action_entity
              ? useCustStore
                  .getState()
                  .valactions.filter(
                    (action) =>
                      action.entity_name === tmlffs[i].ui_data.tml_action_entity
                  )
              : useCustStore
                  .getState()
                  .valactions.filter(
                    (action) =>
                      action.entity_name === tmlffs[i].ui_data.tml_entity_name
                  ),
          },
        };
      }

      // console.log("tmlff :: ", tmlff);
    }

    console.log("json tmls", tmlValues);

    // console.log("tmlActions", tmlActions);

    return tmlValues;
  };

  const tflValuesLoader = async (ff, fVals, params) => {
    console.log("ff in tflvaluesloader :: ", ff);
    var tflValues = {};

    var srcmenulists = {};
    var destmenulists = {};

    var tflffs = ff.filter(
      (ffrow) => ffrow.ui_data.pg_section_name === "transferlist"
    );
    console.log("tflffs", tflffs);

    for (var i = 0; i < tflffs.length; ++i) {
      var ffsrcjson = [];
      var ffdestjson = [];

      console.log("tflfvals ", i, tflffs[i].ui_data?.subtype_src_name);

      ffsrcjson = ff.filter(
        (ffrow) => ffrow.entity_type === tflffs[i].ui_data?.subtype_src_name
      );

      console.log("ffsrcjson", ffsrcjson);

      const ffsrcvalues = await callUICrud(
        tflffs[i].ui_data.subtype_src_name,
        "R",
        {
          user_id: useCustStore.getState().currentUser.id,
          asset_id: params.id,
          grp_id: 3,
        }
      );

      for (var j = 0; j < ffsrcjson.length; ++j) {
        if (ffsrcjson[j].ui_data.type === "select") {
          const menuListValues = await menuListLoader(
            parff.filter((ffrow) => ffrow.ui_data.menulist_name)
          );

          console.log("menulistvals in tflloader", menuListValues);
        }
      }

      console.log("ffrsrcvalues :: ", ffsrcvalues);

      const ffdestvalues = await callUICrud(
        tflffs[i].ui_data.subtype_dest_name,
        "R",
        {
          asset_id: params.id,
        }
      );

      console.log("tempdata2 :: ", ffdestvalues);

      console.log("tflfvals ", i, tflffs[i].ui_data?.subtype_dest_name);

      ffdestjson = ff.filter(
        (ffrow) => ffrow.entity_type === tflffs[i].ui_data?.subtype_dest_name
      );

      console.log("ffdestjson", ffdestjson);

      for (var k = 0; k < ffdestjson.length; ++k) {
        console.log("ffdestjson[k]", ffdestjson[k].ui_data);
        if (ffdestjson[k].ui_data.type === "menu") {
          const menuListValues = await menuListLoader([ffdestjson[k]]);
          console.log("menulistvals in tflloader", menuListValues);

          destmenulists = { ...destmenulists, menuListValues };
          console.log("destmenulist", destmenulists);
        }
      }

      tflValues = {
        ...tflValues,
        [tflffs[i].ui_data.colname]: {
          src: {
            ff: ffsrcjson.filter((field) => field.ui_data.showin_tfl === "Y"),
            fVals: {
              data: ffsrcvalues?.filter((field) => field.statuscd == 0),
            },
            menus: srcmenulists?.menuListValues,
          },
          dest: {
            ff: ffdestjson.filter((field) => field.ui_data.showin_tfl === "Y"),
            fVals: {
              data: ffdestvalues,
            },
            menus: destmenulists?.menuListValues,
          },
        },
      };
      console.log("tflvals", tflValues);
    }

    return tflValues;
  };

  const parFFloader = async (params, fVals) => {
    try {
      console.log("list_route_params :: ", params);
      console.log("list_route_params fvals :: ", fVals);

      var entity_name = params.entity_name;
      if (readFromCache(`ff_${params.entity_name}`)) {
        console.log("using readfromcache");
        var parff = JSON.parse(readFromCache(`ff_${params.entity_name}`));
        var entityActions = JSON.parse(
          readFromCache(`ea_${params.entity_name}`)
        );
      } else {
        var entityActions = useCustStore
          .getState()
          ?.valactions.filter(
            (actions) => actions.entity_name === params.entity_name
          );

        console.log("entityActions :: ", entityActions);
        if (
          entityActions.filter((action) => action.action_type === 7).length > 0
        ) {
          entity_name = entityActions.filter(
            (action) => action.action_type === 7
          )[0].child_path;
        }

        if (entityActions.filter((action) => action.parent_entity).length > 0) {
          entity_name = entityActions.filter(
            (action) => action.parent_entity
          )[0].parent_entity;
        }

        var parff = await callUICrud("ff", "R", {
          user_id: useCustStore.getState().currentUser.id,
          grp_id: useCustStore.getState().currentUser.grp_id,
          cust_id: useCustStore.getState().currentUser.cust_id,
          // entity_type : filteredActions.filter()
          parent_ent: entity_name,
          // parent_ent: params.action_name ? entity_name : `${entity_name}_list`,
        });

        console.log("data2 in add user assets dblloader :: ", parff);

        writeToCache(`ff_${params.entity_name}`, JSON.stringify(parff));
        writeToCache(`ea_${params.entity_name}`, JSON.stringify(entityActions));
        console.log("loaderdata :: ", loaderdata);
      }

      return {
        parff,
        entityActions,
      };
    } catch (e) {
      console.log("e", e);
      return <Navigate to="dashboard" />;
      // throw e;
    }
  };

  const parFFloader2 = async (params, fVals) => {
    try {
      console.log("list_route_params :: ", params);
      console.log("list_route_params fvals :: ", fVals);

      var entity_name = params.entity_name;

      var entityActions = useCustStore
        .getState()
        ?.valactions.filter(
          (actions) => actions.entity_name === params.entity_name
        );

      console.log("entityActions :: ", entityActions);
      if (
        entityActions.filter((action) => action.action_type === 7).length > 0
      ) {
        entity_name = entityActions.filter(
          (action) => action.action_type === 7
        )[0].child_path;
      }

      if (entityActions.filter((action) => action.parent_entity).length > 0) {
        entity_name = entityActions.filter((action) => action.parent_entity)[0]
          .parent_entity;
      }

      var parff = await callUICrud("ff", "R", {
        user_id: useCustStore.getState().currentUser.id,
        grp_id: useCustStore.getState().currentUser.grp_id,
        cust_id: useCustStore.getState().currentUser.cust_id,
        // entity_type : filteredActions.filter()
        parent_ent: entity_name,
        // parent_ent: params.action_name ? entity_name : `${entity_name}_list`,
      });

      console.log("data2 in add user assets dblloader :: ", parff);

      return {
        parff,
        entityActions,
      };
    } catch (e) {
      console.log("e", e);
      return <Navigate to="dashboard" />;
      // throw e;
    }
  };

  const circularProgressSize = 45;
  const ColorMode = useTokenStore(useCallback((state) => state.colorMode));
  const SetColorMode = useTokenStore(
    useCallback((state) => state.setColorMode)
  );
  const SetVpWidth = useTokenStore(useCallback((state) => state.setVpWidth));
  const cust = useCustStore((state) => state.currentUser);
  const custReset = useCustStore((state) => state.reset);

  // const controller = new AbortController();
  const Logout = ({}) => {
    let location = useLocation();
    // const custReset = useCustStore((state) => state.reset);
    custReset();
    return (
      <Navigate
        to="/login"
        state={{
          ReDirRoute: "",
          redirMsg: location.state?.redirMsg
            ? location.state?.redirMsg
            : "You have been logged out, Please log back in here.",
        }}
        replace
      />
    );
  };

  const RedirOverview = ({}) => {
    let location = useLocation();
    // const custReset = useCustStore((state) => state.reset);
    // custReset();
    console.log(
      // "RedirOverview::calledFrom:",
      // calledFrom,
      ", RedirOverview::redirectPath:",
      // redirectPath,
      ", RedirOverview::location.pathname:",
      location.pathname,
      // ", RedirOverview::params:",
      // params,
      ", RedirOverview::validatedUser:",
      validatedUser
    );
    return (
      <Navigate
        to="/overview"
        // state={{
        //   ReDirRoute: "",
        //   redirMsg: location.state?.redirMsg
        //     ? location.state?.redirMsg
        //     : "You have been logged out, Please log back in here.",
        // }}
        replace
      />
    );
  };
  const validatedUser = useCustStore((state) => state.validatedUser);

  const ProtectedRoute = ({ calledFrom, redirectPath, children }) => {
    var validRoute = [];
    let location = useLocation();
    let params = useParams();
    var validAction = null;

    console.log("top of protected route");
    console.log("useCustStore.getState()", useCustStore.getState());
    console.log("params :: ", params);

    console.log(
      "ProtectedRoute::calledFrom:",
      calledFrom,
      ", \nProtectedRoute::redirectPath:",
      redirectPath,
      ", \nProtectedRoute::location.pathname:",
      location.pathname,
      ", \nProtectedRoute::params:",
      params,
      ", \nProtectedRoute::validatedUser:",
      validatedUser
    );

    if (!validatedUser || !useCustStore.getState().valroutes) {
      console.log("ProtectedRoute::validatedUser:", validatedUser);
      return (
        <Navigate
          to="/redirectpage"
          replace
          state={{
            redirRoute: "/login",
            origRoute: location.pathname,
            redirMsg: "Login required for this page, redirecting",
          }}
        />
      );
    }

    if (params.entity_name) {
      console.log("in params.entity_name :: ");
      validRoute = useCustStore
        .getState()
        .valroutes.filter(
          (routes) => routes.entity_name === params.entity_name
        );
    } else {
      console.log("params.entity_name  :: ", params.entity_name);
      return children;
      // validRoute = useCustStore
      //   .getState()
      //   .valroutes.filter((routes) => routes.route === location.pathname);
    }
    console.log("protected route :: validRoute :: ", validRoute);
    console.log("valactions :: ", useCustStore.getState()?.valactions);

    if (validRoute?.length <= 0) {
      return (
        <Navigate
          // to={redirectPath}
          to="/redirectpage"
          state={{
            redirRoute: calledFrom ? calledFrom : redirectRoute,
            redirMsg: "Route not accessible, redirecting to dashboard page",
          }}
          replace
        />
      );
    }
    validAction =
      validRoute &&
      useCustStore
        .getState()
        ?.valactions.filter(
          (actions) =>
            actions.entity_name ===
              (params.entity_name
                ? params.entity_name
                : validRoute[0]?.entity_name) &&
            actions.action_name === (params.action_name ?? "")
        );

    console.log("protected :: validAction :: ", validAction);

    // console.log("validAction :: ", validAction ?? "");
    if (validAction && validAction.length <= 0) {
      // console.log("validAction.length :: ", validAction.length ?? "");
      const redirEntity =
        validRoute[0]?.route + "/" + validRoute[0].entity_name;
      // console.log("redirEntity :: ", redirEntity ?? "");
      return (
        <Navigate
          to="/redirectpage"
          state={{
            redirRoute: calledFrom ? calledFrom : redirectPath,
            redirMsg: "Not a valid action, redirecting",
          }}
          replace
        />
      );
    }

    if (validAction[0].action_type == 6) {
      var tempstr =
        validAction[0].child_path === "user_id"
          ? `/dashboard/ui_list_entity/${validAction[0].entity_name}/U/${
              useCustStore.getState().currentUser.id
            }`
          : validAction[0].child_path === "cust_id"
          ? `/dashboard/ui_list_entity/${validAction[0].entity_name}/U/${
              useCustStore.getState().currentUser.cust_id
            }`
          : validAction[0].child_path === "group"
          ? `/dashboard/ui_list_entity/${validAction[0].entity_name}`
          : `/dashboard`;

      console.log("protected :: tempstr: ", tempstr);

      return <Navigate to={tempstr} replace />;
    }
    return children;
  };

  const myrouter = createBrowserRouter([
    {
      id: "root",
      element: <Main />,

      loader: async () => {
        const genToken = (tokendata, secret) => {
          const token1 = jwtencoder(tokendata, secret, {
            expiresIn: "7d",
          });
          return token1;
        };

        const getDate = () => {
          const date = new Date();
          return date.toLocaleString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          });
        };

        const tokenPayload = {
          pondu: "Pondu Rocks",
          Date: getDate(),
        };

        const dailyToken = () => {
          const secret = process.env.REACT_APP_JWT_SECRET + "-" + getDate();
          const finaltoken = genToken(tokenPayload, secret);
          useTokenStore.setState({ token: finaltoken });
        };
        dailyToken();
        console.log("token declared!", useTokenStore.getState().token);
        // return true;
        const topbanner = await callUICrud("topbanner", "R", {
          svc_pkg_id: useTokenStore.getState().svc_pkg_id,
          user_id: useCustStore.getState().currentUser.id,
          grp_id: useCustStore.getState().currentUser.grp_id,
        });
        const [tiles, slides] = await OverviewLoader();
        const faqData = await HIWLoader();
        console.log("root::topbanner.loader:", topbanner);
        console.log(
          "root::tiles/slides/faqData .loader:",
          tiles,
          slides,
          faqData
        );
        return { topbanner, tiles, slides, faqData };
      },
      shouldRevalidate: ({ currentUrl }) => {
        // only revalidate if the submission originates from
        // this route
        return (
          currentUrl.pathname ===
          "/dashboard/ui_list_entity2/:entity_name/:action_name/:id"
        );
      },
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          id: "beast",
          element: <NavBar />,
          errorElement: <ErrorPage />,
          loader: async () => {
            console.log("path::", location.pathname);

            return true;
          },

          children: [
            {
              index: true,
              path: "/",
              element: (
                <Suspense
                  fallback={
                    <CircularProgress
                      size={circularProgressSize}
                      color="secondary"
                    />
                  }
                >
                  <RedirOverview />,
                </Suspense>
              ),
            },

            {
              path: "howitworks",
              element: (
                <>
                  <HowItWorks />
                </>
              ),
              loader: async () => {
                return true;
              },
            },
            {
              path: "signUp",
              element: <SignUp />,
              loader: async () => {
                try {
                  const ffcust = await callUICrud("ff", "R", {
                    user_id: useCustStore.getState().currentUser.id,
                    grp_id: useCustStore.getState().currentUser.grp_id,
                    cust_id: useCustStore.getState().currentUser.cust_id,
                    entity_type: "cust",
                  });

                  console.log("signUp:loader:ffcust", ffcust);
                  return {
                    ffcust:
                      ffcust &&
                      ffcust.filter((ffrow) => ffrow.show_on_create_page == 1),
                  };
                } catch (e) {
                  console.log("e", e);
                  return <Navigate to="overview" />;
                }
              },
            },
            {
              path: "about",
              async lazy() {
                let { About } = await import("./components/About");
                return { Component: About };
              },
            },
            {
              path: "terms",
              async lazy() {
                let { TermsConditions } = await import("./components/TermPage");
                return { Component: TermsConditions };
              },
            },
            {
              path: "privacy",
              async lazy() {
                let { Privacy } = await import("./components/Privacy");
                return { Component: Privacy };
              },
            },
          ],
        },
        {
          path: "login",
          element: (
            <Suspense
              fallback={
                <CircularProgress
                  size={circularProgressSize}
                  color="secondary"
                />
              }
            >
              <LoginPage />
            </Suspense>
          ),
        },
        {
          path: "logout",
          element: (
            <Suspense
              fallback={
                <CircularProgress
                  size={circularProgressSize}
                  color="secondary"
                />
              }
            >
              <Logout />
            </Suspense>
          ),
        },
        {
          path: "forgotpwd",
          loader: async () => {
            try {
              return { data1: { loc: "forgotpwd" } };
            } catch (e) {
              console.log(e);
            }
          },
          element: (
            <Suspense
              fallback={
                <CircularProgress
                  size={circularProgressSize}
                  color="secondary"
                />
              }
            >
              <PassRecovery />
            </Suspense>
          ),
        },
        {
          path: "changepwd",
          loader: async () => {
            try {
              return { data1: { loc: "changepwd" } };
            } catch (e) {
              console.log(e);
            }
          },
          element: (
            <Suspense
              fallback={
                <CircularProgress
                  size={circularProgressSize}
                  color="secondary"
                />
              }
            >
              <PassRecovery />
            </Suspense>
          ),
        },
        {
          path: "change_grp",
          loader: async () => {
            const res = checkUser(data);
            console.log("LoginPage after checkuser:res", res);
            try {
              return { data1: { loc: "changepwd" } };
            } catch (e) {
              console.log(e);
            }
          },
          element: (
            <Suspense
              fallback={
                <CircularProgress
                  size={circularProgressSize}
                  color="secondary"
                />
              }
            >
              <PassRecovery />
            </Suspense>
          ),
        },
        {
          path: "dashboard",
          id: "db",
          element: (
            <Suspense
              fallback={
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress size={45} color="secondary" />
                </Box>
              }
            >
              <ProtectedRoute
                user={validatedUser}
                calledFrom="/dashboard"
                redirectPath="/login"
              >
                <DashboardLayout />
              </ProtectedRoute>
            </Suspense>
          ),
          loader: async ({ params }) => {
            console.log("path/dashboard:params:", params);

            try {
              return {
                navItemsData: { data: useCustStore.getState()?.navroutes },
                data3: {
                  add: true,
                  edit: true,
                  delete: true,
                  title: "Dashboard",
                },
              };
            } catch (e) {
              console.log("e", e);
              return <Navigate to="dashboard" />;
              // throw e;
            }
          },
          children: [
            {
              path: "",
              element: <MissionControl />,
            },

            {
              path: "marketplace",
              element: (
                <ProtectedRoute user={validatedUser} redirectPath="/dashboard">
                  <DashBdCartEdit />
                </ProtectedRoute>
              ),

              loader: async ({ params }) => {
                try {
                  console.log(
                    "cust id",
                    useCustStore.getState().currentUser.login
                  );

                  const [data2, data4] = await OverviewLoader();
                  console.log("data4 ", data4);

                  return { data2: data4, data3: { add: true } };
                } catch (e) {
                  console.log("e", e);
                  return <Navigate to="overview" />;
                  // throw e;
                }
              },
            },
            {
              path: "ui_list_entity/:entity_name",
              id: "ui_list",

              children: [
                {
                  index: true,
                  path: "",
                  id: "mrt",
                  element: (
                    <ProtectedRoute redirectPath="/dashboard">
                      <MrtList />
                    </ProtectedRoute>
                  ),
                  action: async ({ request }) => {
                    console.log("ui_list_entity:request", request);

                    const formData = Object.fromEntries(
                      await request.formData()
                    );
                    console.log("ui_list_entity::formdata:", formData);

                    console.log(
                      "ui_list_entity_action:entity_name:",
                      formData.entity_name
                    );
                    console.log(
                      "ui_list_entity_action:entity_action:",
                      formData.entity_action
                    );
                    console.log(
                      "ui_list_entity_action:fdata:",
                      JSON.parse(formData.fdata)
                    );

                    if (formData.entity_action === "swemail") {
                      var mailer;
                      for (
                        var i = 0;
                        i < JSON.parse(formData.fdata).length;
                        ++i
                      ) {
                        mailer = await myAxios.post(`/mailer/welcome`, {
                          Email: JSON.parse(formData.fdata)[i].login,
                          Subject: "Beast",
                        });
                      }

                      return [{ statuscd: 0, msg: "email sent" }];
                    } else {
                      console.log(
                        "boolean :: ",
                        formData.allow_rows === "Y" &&
                          !JSON.parse(formData.fdata).length > 0
                      );

                      if (formData.entity_action === "S") {
                        console.log("result selection are");
                        if (
                          formData.statechange &&
                          formData.statechange === "cust_id"
                        ) {
                          useCustStore.setState({
                            currentUser: {
                              ...useCustStore.getState().currentUser,
                              cust_id:
                                JSON.parse(formData.fdata).length > 0
                                  ? JSON.parse(formData.fdata)[0].id
                                  : JSON.parse(formData.fdata).id,
                              cust_name:
                                JSON.parse(formData.fdata).length > 0
                                  ? JSON.parse(formData.fdata)[0].cust_name
                                  : JSON.parse(formData.fdata).cust_name,
                            },
                          });
                        }
                        if (
                          formData.statechange &&
                          formData.statechange === "grp_id"
                        ) {
                          useCustStore.setState({
                            currentUser: {
                              ...useCustStore.getState().currentUser,
                              grp_id:
                                JSON.parse(formData.fdata).length > 0
                                  ? JSON.parse(formData.fdata)[0].grp_id
                                  : JSON.parse(formData.fdata).grp_id,
                            },
                          });
                          const res = await checkUser(
                            useCustStore.getState().currentUser.login,
                            null,
                            true
                          );
                        }

                        console.log(
                          "mrtlist::formData.fdata in if condition of S :: ",
                          JSON.parse(formData.fdata)[0].id
                        );
                        console.log("mrtlist::sending result in select case");
                        return res;
                      } else {
                        console.log(formData.fdata);

                        const res = await callUICrud(
                          formData.entity_name,
                          formData.entity_action,
                          formData.allow_rows === "Y" &&
                            !JSON.parse(formData.fdata).length > 0
                            ? [JSON.parse(formData.fdata)]
                            : JSON.parse(formData.fdata)
                        );
                        console.log("ui_list_entity_action:res:", res);
                        return res;
                      }
                    }
                  },
                  loader: async ({ params }) => {
                    try {
                      const loaderdata = await parFFloader(params);

                      console.log("loaderdata :: ", loaderdata);

                      const { parff, entityActions } = loaderdata;

                      var entity_name = params.entity_name;

                      if (
                        entityActions.filter(
                          (action) => action.action_type === 7
                        ).length > 0
                      ) {
                        entity_name = entityActions.filter(
                          (action) => action.action_type === 7
                        )[0].child_path;
                      }

                      if (
                        entityActions.filter((action) => action.parent_entity)
                          .length > 0
                      ) {
                        entity_name = entityActions.filter(
                          (action) => action.parent_entity
                        )[0].parent_entity;
                      }

                      //load form values based on customer id and route entity name
                      const fVals = await callUICrud(entity_name, "R", {
                        user_id: useCustStore.getState().currentUser.id,
                        grp_id: useCustStore.getState().currentUser.grp_id,
                        cust_id: useCustStore.getState().currentUser.cust_id,
                        sa_cust_id:
                          useCustStore.getState().currentUser.role_name ===
                          "System Admin"
                            ? useCustStore.getState().currentUser.cust_id
                            : null,
                      });
                      console.log("ui_list_entity:data1:", fVals);

                      // const tmlValues = await tmlValuesLoader(
                      //   parff,
                      //   fVals,
                      //   params
                      // );

                      // console.log("tmlValues", tmlValues);

                      return {
                        parff,
                        entityActions,
                        // tmlValues,
                        fVals: { data: fVals },
                      };
                    } catch (e) {}
                  },
                },

                {
                  path: ":action_name/:id",
                  element: (
                    <ProtectedRoute redirectPath="/dashboard">
                      <DashBdDynForm />
                    </ProtectedRoute>
                  ),
                  action: async ({ request }) => {
                    var formData = Object.fromEntries(await request.formData());
                    console.log("ui_action::Object.fromEntries:", formData);
                    formData = {
                      ...formData,
                      fdata: JSON.parse(formData.fdata),
                    };
                    console.log("ui_action::formData:", formData);

                    let xy = unflatten(formData.fdata, {
                      delimiter: "-",
                    });
                    delete xy["test"];

                    var nFormData;
                    if (formData.swemail) {
                      const mailer = await myAxios.post(`/mailer/welcome`, {
                        Email: formData.fdata.login,
                        Subject: "BREazy Order Confirmation",
                      });
                      if (mailer.status === 200) {
                        console.log(Date());
                        nFormData = {
                          ...formData.fdata,
                          wemailsenddate: Date(),
                        };

                        // console.log("refreshCart-res.data:" + JSON.stringify(data));
                      } else {
                        nFormData = formData.fdata;
                      }
                    }

                    const res = await callUICrud(
                      formData.entity_name,
                      formData.entity_action,
                      {
                        ...xy,
                        assignments: formData.fdata.assignments
                          ? JSON.parse(formData.fdata.assignments)
                          : null,
                      }
                    );

                    console.log(" action res ", res);

                    console.log("ui_list_entity/edit::updated");
                    return res;
                  },

                  loader: async ({ params }) => {
                    console.log("  loader params : ", params);

                    const fVals = await callUICrud(params.entity_name, "R", {
                      user_id: useCustStore.getState().currentUser.id,
                      grp_id: useCustStore.getState().currentUser.grp_id,
                      cust_id: useCustStore.getState().currentUser.cust_id,
                      sa_cust_id:
                        useCustStore.getState().currentUser.role_name ===
                        "System Admin"
                          ? useCustStore.getState().currentUser.cust_id
                          : null,
                    });

                    console.log("fVals ", fVals);

                    var flattenedFVals;

                    if (fVals && params.id > 0) {
                      const filterFVals = fVals.filter(
                        (x) => x.id == params.id
                      );

                      flattenedFVals = flatten(filterFVals[0], {
                        delimiter: "-",
                      });
                    }
                    console.log("flattened", flattenedFVals);

                    console.log("usr_assets:params:", params);
                    // **moved this logic in parFFloader**
                    // if (readFromCache(`ff_${params.entity_name}`)) {
                    //   console.log("using readfromcache");
                    //   var parff = JSON.parse(
                    //     readFromCache(`ff_${params.entity_name}`)
                    //   );
                    //   var entityActions = JSON.parse(
                    //     readFromCache(`ea_${params.entity_name}`)
                    //   );
                    // } else {
                    //   const loaderdata = await parFFloader(params, fVals);

                    //   var { parff, entityActions } = loaderdata;
                    //   writeToCache(
                    //     `ff_${params.entity_name}`,
                    //     JSON.stringify(parff)
                    //   );
                    //   writeToCache(
                    //     `ea_${params.entity_name}`,
                    //     JSON.stringify(entityActions)
                    //   );
                    //   console.log("loaderdata :: ", loaderdata);
                    // }
                    const loaderdata = await parFFloader(params, fVals);
                    var { parff, entityActions } = loaderdata;

                    console.log("parff", parff);

                    const trigPHolders = parff.filter(
                      (ffrow) =>
                        ffrow.ui_data.type === "pg_section" &&
                        ffrow.ui_data.subtype_trigger_field?.length > 0
                    );

                    console.log("trigpholders", trigPHolders);

                    const tmlValues = await tmlValuesLoader(
                      parff,
                      fVals,
                      params
                    );

                    const tflValues = await tflValuesLoader(
                      parff,
                      fVals,
                      params
                    );
                    var menuListValues;
                    if (!readFromCache(`mlv_${params.entity_name}`)) {
                      menuListValues = await menuListLoader(
                        parff.filter((ffrow) => ffrow.ui_data.menulist_name)
                      );
                      console.log(
                        "detail route loader menuListValues :: ",
                        menuListValues
                      );
                      writeToCache(
                        `mlv_${params.entity_name}`,
                        JSON.stringify(menuListValues)
                      );
                    } else if (readFromCache(`mlv_${params.entity_name}`)) {
                      menuListValues = JSON.parse(
                        readFromCache(`mlv_${params.entity_name}`)
                      );
                    }

                    console.log(
                      "detail route loader tblmenulist values :: ",
                      tmlValues
                    );

                    // if (params.action_name === "U") {
                    console.log("localStorage", localStorage);

                    for (var i = 0; i < trigPHolders.length; ++i) {
                      console.log(
                        "trigpholder info by index",
                        flattenedFVals[
                          trigPHolders[i]?.ui_data.subtype_trigger_field
                        ],
                        flattenedFVals
                      );
                      if (
                        flattenedFVals[
                          trigPHolders[i].ui_data.subtype_trigger_field
                        ]
                      ) {
                        console.log("in flattenedfval case");
                        if (!readFromCache(trigPHolders[i].ui_data.colname))
                          writeToCache(
                            trigPHolders[i].ui_data.colname,
                            flattenedFVals[
                              trigPHolders[i].ui_data.subtype_trigger_field
                            ]
                              ? flattenedFVals[
                                  trigPHolders[i].ui_data.subtype_trigger_field
                                ]
                              : null
                          );
                        // console.log(
                        //   "beast trigpholder : ",
                        //   trigPHolders[i],
                        //   flattenedFVals[
                        //     trigPHolders[i].ui_data.subtype_trigger_field
                        //   ]
                        // );

                        const trigFields = await callUICrud("ff", "R", {
                          i_page: "E",
                          i_entity_id: readFromCache(
                            trigPHolders[i].ui_data.colname
                          ),
                          entity_type: trigPHolders[i].ui_data.subtype_name,
                        });

                        console.log("trigFields :: ", trigFields);
                        if (trigFields) {
                          parff = parff.concat(trigFields);
                          console.log("parff.length", parff.length);
                        }

                        console.log(
                          "trigpholder subfields :: ",
                          i,
                          trigFields,
                          parff
                        );
                      }
                    }
                    // }

                    if (params.action_name === "C") {
                      console.log("localStorage", localStorage);
                      for (var i = 0; i < trigPHolders.length; ++i) {
                        if (readFromCache(trigPHolders[i].ui_data.colname)) {
                          console.log("beast trigpholder : ", trigPHolders[i]);

                          const trigFields = await callUICrud("ff", "R", {
                            i_page: "E",
                            i_entity_id: readFromCache(
                              trigPHolders[i].ui_data.colname
                            ),
                            entity_type: trigPHolders[i].ui_data.subtype_name,
                          });

                          console.log("trigfields :: ", trigFields);

                          if (trigFields) {
                            parff = parff.concat(trigFields);
                            console.log("parff.length", parff.length);
                          }

                          console.log(
                            "trigpholder subfields :: ",
                            i,
                            trigFields,
                            parff
                          );
                        }
                      }
                    }

                    return {
                      parff,
                      menuListValues,
                      entityActions,
                      tmlValues,
                      tflValues,
                      fVals: params.id > 0 ? [flattenedFVals] : null,
                    };
                  },
                },
              ],
            },
            {
              path: "ui_list_entity2/:entity_name",
              id: "ui_list2",

              children: [
                {
                  index: true,
                  path: "",
                  id: "mrt2",
                  element: (
                    <ProtectedRoute redirectPath="/dashboard">
                      <>
                        <DashBdDynForm mode="L" />
                      </>
                    </ProtectedRoute>
                  ),
                  action: async ({ request }) => {
                    console.log("ui_list_entity:request", request);

                    const formData = Object.fromEntries(
                      await request.formData()
                    );
                    console.log("ui_list_entity::formdata:", formData);

                    console.log(
                      "ui_list_entity_action:entity_name:",
                      formData.entity_name
                    );
                    console.log(
                      "ui_list_entity_action:entity_action:",
                      formData.entity_action
                    );
                    console.log(
                      "ui_list_entity_action:fdata:",
                      JSON.parse(formData.fdata)
                    );

                    if (formData.entity_action === "swemail") {
                      var mailer;
                      for (
                        var i = 0;
                        i < JSON.parse(formData.fdata).length;
                        ++i
                      ) {
                        mailer = await myAxios.post(`/mailer/welcome`, {
                          Email: JSON.parse(formData.fdata)[i].login,
                          Subject: "Beast",
                        });
                      }

                      return [{ statuscd: 0, msg: "email sent" }];
                    } else {
                      console.log(
                        "boolean :: ",
                        formData.allow_rows === "Y" &&
                          !JSON.parse(formData.fdata).length > 0
                      );

                      if (formData.entity_action === "S") {
                        console.log("result selection are");
                        if (
                          formData.statechange &&
                          formData.statechange === "cust_id"
                        ) {
                          useCustStore.setState({
                            currentUser: {
                              ...useCustStore.getState().currentUser,
                              cust_id:
                                JSON.parse(formData.fdata).length > 0
                                  ? JSON.parse(formData.fdata)[0].id
                                  : JSON.parse(formData.fdata).id,
                              cust_name:
                                JSON.parse(formData.fdata).length > 0
                                  ? JSON.parse(formData.fdata)[0].cust_name
                                  : JSON.parse(formData.fdata).cust_name,
                            },
                          });
                        }
                        if (
                          formData.statechange &&
                          formData.statechange === "grp_id"
                        ) {
                          useCustStore.setState({
                            currentUser: {
                              ...useCustStore.getState().currentUser,
                              grp_id:
                                JSON.parse(formData.fdata).length > 0
                                  ? JSON.parse(formData.fdata)[0].grp_id
                                  : JSON.parse(formData.fdata).grp_id,
                            },
                          });
                          const res = await checkUser(
                            useCustStore.getState().currentUser.login,
                            null,
                            true
                          );
                        }

                        console.log(
                          "mrtlist::formData.fdata in if condition of S :: ",
                          JSON.parse(formData.fdata)[0].id
                        );
                        console.log("mrtlist::sending result in select case");
                        return res;
                      } else {
                        console.log(formData.fdata);

                        const res = await callUICrud(
                          formData.entity_name,
                          formData.entity_action,
                          formData.allow_rows === "Y" &&
                            !JSON.parse(formData.fdata).length > 0
                            ? [JSON.parse(formData.fdata)]
                            : JSON.parse(formData.fdata)
                        );
                        console.log("ui_list_entity_action:res:", res);
                        return res;
                      }
                    }
                  },
                  loader: async ({ params }) => {
                    try {
                      const loaderdata = await parFFloader2(params);

                      console.log("loaderdata :: ", loaderdata);

                      const { parff, entityActions } = loaderdata;

                      var entity_name = params.entity_name;

                      if (
                        entityActions.filter(
                          (action) => action.action_type === 7
                        ).length > 0
                      ) {
                        entity_name = entityActions.filter(
                          (action) => action.action_type === 7
                        )[0].child_path;
                      }

                      if (
                        entityActions.filter((action) => action.parent_entity)
                          .length > 0
                      ) {
                        entity_name = entityActions.filter(
                          (action) => action.parent_entity
                        )[0].parent_entity;
                      }

                      //load form values based on customer id and route entity name
                      const fVals = await callUICrud(entity_name, "R", {
                        user_id: useCustStore.getState().currentUser.id,
                        grp_id: useCustStore.getState().currentUser.grp_id,
                        cust_id: useCustStore.getState().currentUser.cust_id,
                        sa_cust_id:
                          useCustStore.getState().currentUser.role_name ===
                          "System Admin"
                            ? useCustStore.getState().currentUser.cust_id
                            : null,
                      });
                      console.log("ui_list_entity:data1:", fVals);

                      const tmlValues = await tmlValuesLoader(
                        parff,
                        fVals,
                        params
                      );

                      console.log("tmlValues", tmlValues);

                      return {
                        parff,
                        entityActions,
                        tmlValues,
                        fVals: { data: fVals },
                      };
                    } catch (e) {}
                  },
                },

                {
                  path: ":action_name/:id",
                  element: (
                    <ProtectedRoute redirectPath="/dashboard">
                      <>
                        <DashBdDynForm mode="U" />
                      </>
                    </ProtectedRoute>
                  ),
                  action: async ({ request }) => {
                    var formData = Object.fromEntries(await request.formData());
                    console.log("ui_action::Object.fromEntries:", formData);
                    formData = {
                      ...formData,
                      fdata: JSON.parse(formData.fdata),
                    };
                    console.log("ui_action::formData:", formData);

                    let xy = unflatten(formData.fdata, {
                      delimiter: "-",
                    });
                    delete xy["test"];

                    var nFormData;
                    if (formData.swemail) {
                      const mailer = await myAxios.post(`/mailer/welcome`, {
                        Email: formData.fdata.login,
                        Subject: "BREazy Order Confirmation",
                      });
                      if (mailer.status === 200) {
                        console.log(Date());
                        nFormData = {
                          ...formData.fdata,
                          wemailsenddate: Date(),
                        };

                        // console.log("refreshCart-res.data:" + JSON.stringify(data));
                      } else {
                        nFormData = formData.fdata;
                      }
                    }

                    const res = await callUICrud(
                      formData.entity_name,
                      formData.entity_action,
                      {
                        ...xy,
                        assignments: formData.fdata.assignments
                          ? JSON.parse(formData.fdata.assignments)
                          : null,
                      }
                    );

                    console.log(" action res ", res);

                    console.log("ui_list_entity/edit::updated");
                    return res;
                  },

                  loader: async ({ params }) => {
                    console.log("  loader params : ", params);

                    const fVals = await callUICrud(params.entity_name, "R", {
                      user_id: useCustStore.getState().currentUser.id,
                      grp_id: useCustStore.getState().currentUser.grp_id,
                      cust_id: useCustStore.getState().currentUser.cust_id,
                      sa_cust_id:
                        useCustStore.getState().currentUser.role_name ===
                        "System Admin"
                          ? useCustStore.getState().currentUser.cust_id
                          : null,
                    });

                    console.log("fVals ", fVals);

                    var flattenedFVals;

                    if (fVals && params.id > 0) {
                      const filterFVals = fVals.filter(
                        (x) => x.id == params.id
                      );

                      flattenedFVals = flatten(filterFVals[0], {
                        delimiter: "-",
                      });
                    }
                    console.log("flattened", flattenedFVals);

                    console.log("usr_assets:params:", params);

                    const loaderdata = await parFFloader2(params, fVals);

                    var { parff, entityActions } = loaderdata;

                    const trigPHolders = parff.filter(
                      (ffrow) =>
                        ffrow.ui_data.type === "pg_section" &&
                        ffrow.ui_data.subtype_trigger_field?.length > 0
                    );

                    console.log("trigpholders", trigPHolders);

                    if (params.action_name === "U") {
                      for (var i = 0; i < trigPHolders.length; ++i) {
                        console.log(
                          "trigpholder info by index",
                          flattenedFVals[
                            trigPHolders[i]?.ui_data.subtype_trigger_field
                          ],
                          flattenedFVals
                        );
                        if (
                          flattenedFVals[
                            trigPHolders[i].ui_data.subtype_trigger_field
                          ]
                        ) {
                          console.log(
                            "beast trigpholder : ",
                            trigPHolders[i],
                            flattenedFVals[
                              trigPHolders[i].ui_data.subtype_trigger_field
                            ]
                          );

                          const trigFields = await callUICrud("ff", "R", {
                            i_page: "E",
                            i_entity_id:
                              flattenedFVals[
                                trigPHolders[i].ui_data.subtype_trigger_field
                              ],
                            entity_type: trigPHolders[i].ui_data.subtype_name,
                          });
                          if (trigFields) {
                            parff = parff.concat(trigFields);
                            console.log("parff.length", parff.length);
                          }

                          console.log(
                            "trigpholder subfields :: ",
                            i,
                            trigFields,
                            parff
                          );
                        }
                      }
                    }

                    if (params.action_name === "C") {
                      for (var i = 0; i < trigPHolders.length; ++i) {
                        if (readFromCache(trigPHolders[i].ui_data.colname)) {
                          console.log("beast trigpholder : ", trigPHolders[i]);

                          const trigFields = await callUICrud("ff", "R", {
                            i_page: "E",
                            i_entity_id: trigPholders[i].ui_data.colname,
                            entity_type: trigPHolders[i].ui_data.subtype_name,
                          });
                          if (trigFields) {
                            parff = parff.concat(trigFields);
                            console.log("parff.length", parff.length);
                          }

                          console.log(
                            "trigpholder subfields :: ",
                            i,
                            trigFields,
                            parff
                          );
                        }
                      }
                    }

                    console.log("loaderdata :: ", loaderdata);
                    const tmlValues = await tmlValuesLoader(
                      parff,
                      fVals,
                      params
                    );

                    const tflValues = await tflValuesLoader(
                      parff,
                      fVals,
                      params
                    );

                    const menuListValues = await menuListLoader(
                      parff.filter((ffrow) => ffrow.ui_data.menulist_name)
                    );
                    console.log(
                      "detail route loader menuListValues :: ",
                      menuListValues
                    );

                    console.log(
                      "detail route loader tblmenulist values :: ",
                      tmlValues
                    );

                    return {
                      parff,
                      menuListValues,
                      entityActions,
                      tmlValues,
                      tflValues,
                      fVals: params.id > 0 ? [flattenedFVals] : null,
                    };
                  },
                },
              ],
            },
          ],
        },

        {
          // catch all for dashboard route
          path: "redirectpage",
          element: (
            <Suspense
              fallback={
                <CircularProgress
                  size={circularProgressSize}
                  color="secondary"
                />
              }
            >
              <ReDirectPage />
            </Suspense>
          ),
        },
        {
          // catch all for dashboard route
          path: "*",
          element: (
            <Suspense
              fallback={
                <CircularProgress
                  size={circularProgressSize}
                  color="secondary"
                />
              }
            >
              <Navigate
                to="/redirectpage"
                state={{
                  redirRoute: "/dashboard",
                  redirMsg: "InValid route, redirecting",
                }}
              />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "wlist",
      id: "wlist",
      element: (
        <>
          <LoginPage />
        </>
      ),
      // loader: async () => {
      //   console.log("overview:loader");
      //   return true;
      // },
    },
    {
      // catch all route
      path: "*",
      element: (
        <Suspense
          fallback={
            <CircularProgress size={circularProgressSize} color="secondary" />
          }
        >
          <Navigate
            to="/"
            state={{
              redirRoute: "/dashboard",
              redirMsg: "InValid route, redirecting",
            }}
          />
        </Suspense>
      ),
    },
  ]);

  const newColorMode = useMediaQuery("(prefers-color-scheme: dark)")
    ? "dark"
    : "light";
  // console.log("WinColorMode", WinColorMode);
  useEffect(() => {
    SetColorMode(newColorMode);
    // console.log("newColorMode", newColorMode);
    //     window.writeToCache("colorMode", colorMode);
  }, [newColorMode]);
  // console.log("colorMode3", ColorMode);

  useEffect(() => {
    // console.log("width of page", siteWidth);

    function handleResize() {
      // setWidth(window.innerWidth);
      // setWidth(Math.min(window.innerWidth, 1080));
      SetVpWidth(Math.min(window.innerWidth, 1080));
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const theme = useMemo(() =>
    responsiveFontSizes(createTheme(themeSettings(ColorMode)), [ColorMode])
  );
  return (
    <React.Fragment>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ConfirmProvider>
            <CssBaseline>
              <Suspense
                fallback={
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress size={45} color="secondary" />
                  </Box>
                }
              >
                <RouterProvider router={myrouter} />
              </Suspense>
            </CssBaseline>
          </ConfirmProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Fragment>
  );
}

//makes sure all props passed to the app.js object are of the correct type
App.propTypes = {};

export default App;
