import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { checkUser } from "../store/zuCustActions";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { SignUpCusTxtFld } from "../home/SignUpCusTxtFld";
import { loginUI } from "../store/custUIFormFields";
import StyledButton from "../shared/StyledButton";
import StyledHeader from "../shared/StyledHeader";
import { Button } from "@mui/material";
import StyledSection from "../shared/StyledSection";
import { Link } from "@mui/material";
import {
  Link as RouterLink,
  useNavigate,
  useLocation,
  redirect,
  Navigate,
} from "react-router-dom";
import { useCartStore } from "../store/zuCartStore";
import { useCustStore } from "../store/zuCustStore";
import myAxios from "../MyAxios";
import axios from "axios";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { createYupSchema } from "../utils/jsFuncions";

// import { setValidatedUser } from "../store/zuCustActions";

export function LoginPage(props) {
  const [logout, setLogout] = useState(false);

  const locData = useLocation();
  const validatedUser = useCustStore((state) => state.validatedUser);
  // const custReset = useCustStore((state) => state.reset);
  // const cartReset = useCartStore((state) => state.reset);

  // const CurrentUser = useCustStore((state) => state.currentUser);
  // useEffect(() => {
  //   console.log("useCustStore in LoginPage");
  //   custReset();
  //   cartReset();
  // }, []);
  const navigate = useNavigate();

  useEffect(() => {
    // if (locData.pathname === "/logout" && !logout) {
    // custReset();
    // setLogout(true);
    // console.log("logout", logout);
    // } else
    if (useCustStore.getState().validatedUser) {
      console.log(
        "LoginPage:Useeffect:validatedUser",
        useCustStore.getState().validatedUser
      );
      console.log("LoginPage:locData", locData);
      useCartStore.setState({
        cart: [],
      });
      if (locData.state?.redirRoute) {
        navigate(locData.state.redirRoute);
      } else {
        navigate("/dashboard");
      }
    }
  }, [useCustStore.getState().validatedUser]);

  console.log("LoginPage::locData:", locData);
  const localCheck = async (data, e) => {
    try {
      console.log("values", data);
      const x = await axios.post(
        "https://brflisrv.reliancetechnologies.com/ui/handleCrud",
        {
          crud_json: { obj_type: "waitlist", crud_action: "C" },
          data: data,
        }
      );

      navigate("/");
    } catch (err) {
      throw err;
    }
  };

  const methods = useForm({
    shouldUnregister: false,
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  return (
    <>
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack
          sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
        >
          <Stack sx={{ alignItems: "center", width: "100%", mb: 2 }}>
            <Box sx={{ fontWeight: "bold", color: "#3f6b4b" }}>
              welcome to btrflai
            </Box>
            <Box sx={{ color: "#3f6b4b" }}>see you soon</Box>
          </Stack>
          <FormProvider {...methods}>
            <form>
              <Stack
                direction="column"
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  // width: "100%",
                }}
              >
                <SignUpCusTxtFld
                  allprops={{
                    type: "text",
                    colname: "name",
                    collabel: "name",
                    introline: "Name",
                    validationType: "string",
                  }}
                  onfieldChg={(data) => {
                    console.log("data", data);
                    // addNewUserInfo(field.ui_data.colname, data);
                  }}
                />
                <SignUpCusTxtFld
                  allprops={{
                    type: "text",
                    colname: "email",
                    collabel: "email",
                    introline: "Email",
                    validationType: "string",
                  }}
                  onfieldChg={(data) => {
                    console.log("data", data);
                    // addNewUserInfo(field.ui_data.colname, data);
                  }}
                />
              </Stack>

              <Stack sx={{ mt: 3, alignItems: "flex-start" }}>
                <Button
                  type="submit"
                  sx={{ py: 0.5, width: "100%", color: "#3f6b4b" }}
                  onClick={methods.handleSubmit(localCheck)}
                >
                  supercharge ur social life
                </Button>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
}

LoginPage.propTypes = {};

// export default LoginPage;
